// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //



// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 7px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 5px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 7px;
        background-color: $grey500;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

.pac-container {
    z-index: 1500 !important;
}

.fullwidth {
    width: 100%;
}

.MuiTreeItem-content {
    padding: 8px 15px;
}

.add-image-box {
    width: 100%;
    height: 100%;
    min-height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed $grey100;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        border: 1px dashed $grey300;
    }
    & svg {
        color: $grey300;
    }
    & img {
        width: 100%;
        height: 120px;
        object-fit: cover;
    }
}

.spacer {
    padding: 3px;
}

.form-section {
    width: 100%;
    padding: 15px;
    border: 1px dashed $grey100;
}

.service_options_container {
    position: relative;
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    border: 1px dashed $grey100;
    margin: 15px 0;
}

.services_actions {
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.price-section {
    font-size: 2rem;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.price-section.discount {
    text-decoration: line-through;
    margin-right: 10px;
}

#input-file {
    display: none;
}

.input-file-name {
    display: none;
}

.api_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.form-block {
    width: 100%;
    padding: 40px;
    margin-left: 24px;
    border: 1px dashed #AAA;
    border-radius: 20px;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}